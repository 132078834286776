<template>
  <div class="holder">
    <div class="download">    
        Download Mac: <a @click="onClickDownload('mac')" href="./zip/monso-mac.zip">monso-mac.zip</a><br>
        Download Windows: <a @click="onClickDownload('pc')" href="./zip/monso-pc.zip">monso-pc.zip</a><br>
    </div>
  </div>

  <div class="cloud-holder">
        <!--<div class="cloud"></div>-->
  </div>

  <div class="centered">
    <img class="ms-logo"  :src="`./img/ms-logo.png`">
  </div>

  <div class="footer">
    <div class="ground"></div>
  </div>

</template>


<script>

import { onBeforeMount, onMounted, shallowRef, toRaw, markRaw, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useGtm } from '@gtm-support/vue-gtm';

export default {  

  setup() {
    const store = useStore();
    const gtm = useGtm();

    onBeforeMount( async() => {
      store.dispatch("site/setTitle", "Monkey Society");
      const metadata = [
          { name: "description", content:"Gathering berries, hunting for food and fighting monsters. Everyday life was mundane and empty. You started building and recruiting monkeys." },
        ];
      store.dispatch("site/setMetadata", metadata);
    })

    onMounted( async() => {
      setupCloud()
      $( window ).resize(function() {
        $('.cloud-holder').html("");
        TweenMax.killAll();
        TweenMax.delayedCall(1,setupCloud);
      });

      function setupCloud() {
        var w = $(window).width();
        var count = w/150;
        for (var i = 0; i < count; i++) {
          var cloud = $("<div>").addClass("cloud");
          var r1 = Math.random()*w;
          var r2 = Math.random()*80 + 120;
          cloud.css({"left":r1, "top": r2});
          $('.cloud-holder').append(cloud);

          var dis = w-r1;
          TweenMax.to(cloud,dis/30,{left:w,ease:Linear.easeNone,onComplete:repeatCloud,onCompleteParams:[cloud]});
        }
      }

      function repeatCloud(cloud) {
        var w = $(window).width();
        var r1 = Math.random()*w;
        var r2 = Math.random()*80 + 120;
        cloud.css({"left":-58, "top": r2});
        $('.cloud-holder').append(cloud);

        var dis = w- (-58);
        TweenMax.to(cloud,dis/30,{left:w,ease:Linear.easeNone,onComplete:repeatCloud,onCompleteParams:[cloud]});
      }
    })


    const onClickDownload = (os) => {
      gtm.trackEvent({
        event: 'download-monso-zip',
        category: "monso",
        action: 'click',
        label: os,
        value: 5000,
        noninteraction: false
      });
    }    


    return {
      onClickDownload,
    }
  }

};


</script>


<style lang="scss">
  .holder { min-height:calc(100vh - 136px); 
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b1d8f5+-1,b1d8f5+39,e1ffff+100 */
  background: #b1d8f5; /* Old browsers */
  background: -moz-linear-gradient(top,  #b1d8f5 -1%, #b1d8f5 39%, #e1ffff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #b1d8f5 -1%,#b1d8f5 39%,#e1ffff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #b1d8f5 -1%,#b1d8f5 39%,#e1ffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b1d8f5', endColorstr='#e1ffff',GradientType=0 ); /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e1ffff', endColorstr='#b1d8f5',GradientType=0 ); /* IE6-9 */

 
  
  }
  .download { position:absolute; margin:10px 0 0 10px; padding:0; font-size:0.9em;}
  .ground {
    width: auto;
    height:80px;
    background: url('/img/bottom.png') center;
  }
  .cloud-holder { position: relative; }
  .cloud {
    position: fixed;
    width: 58px;
    height: 20px;
    background: url('/img/cloud.png') center;
  }
  .centered {
    position: fixed; /* or absolute */
    top: 40%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
  }

  .ms-logo { width: 115px; height: 50px;  }

</style>
